import React from "react";
import { Box, Container, Typography, Grid2 } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const WhyUsSection: React.FC = () => {
  return (
    <Box
      id="why-us"
      sx={{
        py: 10,
        bgcolor: "white",
        backgroundImage: "url(/profile/dragon.png)", // Add background image
        backgroundSize: "cover", // Ensure the image covers the entire area
        backgroundPosition: "center right", // Align dragon image to the right
        backgroundRepeat: "no-repeat", // Prevent tiling
        minHeight: "75vh",
      }}
    >
      <Container maxWidth="lg">
        {/* Left-Aligned Content */}
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 8 }}>
          <Grid2
            container
            spacing={4}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ maxWidth: "600px" }} // Set a max-width for better alignment
          >
            {/* Transparent Box with Title and Key Points */}
            <Grid2 size={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  bgcolor: "rgba(238, 242, 255, 0.9)", // Slightly transparent background
                  borderRadius: 2,
                  p: 4,
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Add box shadow
                }}
              >
                {/* Section Title */}
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    mb: 4,
                    color: "#1f2937", // Darker text color for visibility
                  }}
                >
                  Why Choose Us?
                </Typography>

                {/* Key Points */}
                {/* Point 1 */}
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "1.5em",
                      height: "1.5em",
                      borderRadius: "50%",
                      bgcolor: "#10b981",
                      mt: "2px",
                      flexShrink: 0,
                    }}
                  >
                    <CheckIcon sx={{ color: "white", fontSize: "1em" }} />
                  </Box>

                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        lineHeight: 1.5,
                        mb: 2,
                        fontSize: "1.4rem",
                      }}
                    >
                      Engaging Content
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        color: "#4b5563",
                      }}
                    >
                      Interactive stories that captivate children and make learning enjoyable.
                    </Typography>
                  </Box>
                </Box>

                {/* Point 2 */}
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "1.5em",
                      height: "1.5em",
                      borderRadius: "50%",
                      bgcolor: "#10b981",
                      mt: "2px",
                      flexShrink: 0,
                    }}
                  >
                    <CheckIcon sx={{ color: "white", fontSize: "1em" }} />
                  </Box>

                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        lineHeight: 1.5,
                        mb: 2,
                        fontSize: "1.4rem",
                      }}
                    >
                      Educational Value
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        color: "#4b5563",
                      }}
                    >
                      Stories designed to support language development, creativity, and cognitive growth.
                    </Typography>
                  </Box>
                </Box>

                {/* Point 3 */}
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "1.5em",
                      height: "1.5em",
                      borderRadius: "50%",
                      bgcolor: "#10b981",
                      mt: "2px",
                      flexShrink: 0,
                    }}
                  >
                    <CheckIcon sx={{ color: "white", fontSize: "1em" }} />
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        lineHeight: 1.5,
                        mb: 2,
                        fontSize: "1.4rem",
                      }}
                    >
                      Cultural Diversity
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                        color: "#4b5563",
                      }}
                    >
                      A rich collection of stories from around the world, fostering global awareness and inclusivity.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyUsSection;
