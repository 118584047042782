import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const HomeSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      id="home"
      sx={{
        minHeight: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center", // Center content horizontally
        py: 4,
        backgroundImage: "url('/profile/background.png')", // Add background image
        backgroundSize: "cover", // Cover the entire section
        backgroundPosition: "center", // Center the background
        backgroundRepeat: "no-repeat", // Prevent tiling
      }}
    >
      <Container>
        <Box
          sx={{
            maxWidth: "700px", // Limit the width of the content
            textAlign: "center", // Center-align text
            mx: "auto",
            bgcolor: "rgba(249, 250, 251, 0.8)", // Semi-transparent #f9fafb
            p: 4, // Add padding for the content
            borderRadius: 2, // Rounded corners
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow
          }}
        >
          {/* Centered Content */}
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              color: "#1f2937", // gray 800
              mb: 3,
              fontSize: { xs: "2rem", md: "3rem" }, // Responsive font size
            }}
          >
            Revolutionising storytelling for a new generation
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              fontSize: "1.5rem",
              color: "#4b5563", // gray 600
            }}
          >
            Interactive stories that inspire children's learning, cultural exploration and imagination.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/our-story")} // Navigate to /our-story
              sx={{
                backgroundColor: "#4cb4f6", // Button background color
                color: "#ffffff", // Button text color
                "&:hover": {
                  backgroundColor: "#39a3e0", // Slightly darker shade on hover
                },
                px: 6,
                py: 1.5,
                textTransform: "none",
                borderRadius: 50,
                fontSize: "1.2rem",
              }}
            >
              Learn More About Us
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeSection;
