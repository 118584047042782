import React from "react";
import { Box, Typography, Container } from "@mui/material";

const OurStorySection: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        py: 6,
        backgroundImage: `url('/profile/our_story.png')`, // Set background image
        backgroundSize: "cover", // Make sure the image covers the entire section
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
        color: "#ffffff", // Ensure text is readable on the background
      }}
    >
      <Container
        sx={{
          backgroundColor: "rgba(31, 41, 55, 0.8)", // Dark grey background with transparency
          borderRadius: "24px", // Rounded corners for the container
          p: 4, // Padding inside the container
          color: "#ffffff", // White text for contrast
          maxWidth: "900px", // Limit the container width
          mx: "auto", // Center the container horizontally
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)", // Stronger shadow for depth
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            mb: 4,
            textAlign: "center",
            background: "linear-gradient(to right, #4cb4f6, #d0ebff)", // Gradient from blue to a lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the background visible through the text
          }}
        >
          Our Story
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 3,
            background: "linear-gradient(to right, #4cb4f6, #ffffff)", // Subtle gradient from blue to lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the gradient visible
          }}
        >
          Transforming education through storytelling
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#ffffff", // White text for long content
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
            textAlign: "justify",
          }}
        >
          Stories are the foundation of children's imagination, education and
          connection. In today's digital world, storytelling has the potential
          to become more interactive, personalised and educational than ever
          before.
        </Typography>

        {/* What It Is About */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 3,
            background: "linear-gradient(to right, #4cb4f6, #ffffff)", // Subtle gradient from blue to lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the gradient visible
          }}
        >
          What Is I Edu Tales About?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#ffffff", // White text for long content
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
            textAlign: "justify",
          }}
        >
          I Edu Tales reimagines the way children, parents and educators engage
          with stories. By combining cutting-edge AI technology with creative
          storytelling, we empower young minds to learn, imagine and grow,
          offering parents and educators a meaningful tool to inspire and
          educate.
        </Typography>

        {/* What I Edu Tales Proposes to Do */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 3,
            background: "linear-gradient(to right, #4cb4f6, #ffffff)", // Subtle gradient from blue to lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the gradient visible
          }}
        >
          The Challenge in Modern Storytelling
        </Typography>
        <Box
          sx={{
            color: "#ffffff",
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
            textAlign: "justify",
          }}
        >
          <ul
            style={{ paddingLeft: "1.5rem", margin: 0, listStyleType: "disc" }}
          >
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Parents</strong> struggle to find educational and personalised stories
                that are in line with the child's developmental needs, while at
                the same time addressing concerns about excessive screen time
                and limited interactive family learning experiences.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Educators</strong> face challenges in keeping children engaged while
                teaching language, values, and cultural awareness, with limited
                access to affordable tools that integrate creativity and
                storytelling into the curriculum.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Children</strong> often lose interest in traditional storytelling, which
                lacks interactivity and personalisation, making it less engaging
                in a digital-first world. At the same time, they spend excessive
                hours on devices that fragment attention and limit opportunities
                to develop critical skills such as language, creativity, and
                empathy.
              </Typography>
            </li>
          </ul>
        </Box>

        {/* How the Product Works */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 3,
            background: "linear-gradient(to right, #4cb4f6, #ffffff)", // Subtle gradient from blue to lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the gradient visible
          }}
        >
          How Does I Edu Tales Work?
        </Typography>
        <Box
          sx={{
            color: "#ffffff",
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem", mr: 1 }}>
              1.
            </Typography>
            <Typography sx={{ fontSize: "1.2rem" }}>
              <strong>Choose Your Story Elements:</strong> Children and parents
              start by selecting the key ingredients of their story: characters,
              plot, length, genre, and format.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem", mr: 1 }}>
              2.
            </Typography>
            <Typography sx={{ fontSize: "1.2rem" }}>
              <strong>AI-Powered Storytelling and Learning:</strong> Our AI
              assistant blends storytelling with educational content, creating a
              personalised, engaging narrative enriched with interactive
              learning elements.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem", mr: 1 }}>
              3.
            </Typography>
            <Typography sx={{ fontSize: "1.2rem" }}>
              <strong>Experience Interactive Stories:</strong> Through vibrant
              animations and interactive activities, children explore new
              concepts, languages, and cultures while enhancing their vocabulary
              and grammar skills. Children can transform their digital tales
              into printed books or personalised products, creating lasting
              memories and reinforcing their learning journey.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem", mr: 1 }}>
              4.
            </Typography>
            <Typography sx={{ fontSize: "1.2rem" }}>
              <strong>Foster Family Collaboration:</strong> I Edu Tales
              encourages shared storytelling, strengthening family connections
              through creative and educational experiences.
            </Typography>
          </Box>
        </Box>

        {/* Why We Are Unique */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 3,
            background: "linear-gradient(to right, #4cb4f6, #ffffff)", // Subtle gradient from blue to lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the gradient visible
          }}
        >
          Why we are unique?
        </Typography>
        <Box
          sx={{
            color: "#ffffff",
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
            textAlign: "justify",
          }}
        >
          <ul
            style={{ paddingLeft: "1.5rem", margin: 0, listStyleType: "disc" }}
          >
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Dynamic Engagement:</strong> We go beyond static
                illustrations and voiceovers by integrating animated narration,
                choose-your-own-adventure formats, and interactive storytelling
                tools that keep children deeply engaged.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Educational Integration:</strong> Our platform
                seamlessly incorporates language learning, vocabulary building,
                and cultural deepening to ensure that each story is engaging and
                enriching.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Personalised Keepsakes:</strong> Children can turn their
                digital creations into printed books or products, turning their
                stories into precious memories.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Holistic Experience:</strong> By combining storytelling,
                animation, cultural exploration, and interactive learning, we
                offer an unparalleled mix of education, creativity, and
                connection.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography
                sx={{ fontSize: "1.2rem", lineHeight: "1.8", color: "#ffffff" }}
              >
                <strong>Family Co-Creation:</strong> Our platform fosters family
                involvement, encouraging moments of shared storytelling that
                strengthen bonds and create lasting memories.
              </Typography>
            </li>
          </ul>
        </Box>
        {/* Our Vision */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 3,
            background: "linear-gradient(to right, #4cb4f6, #ffffff)", // Subtle gradient from blue to lighter blue
            WebkitBackgroundClip: "text", // Clip the gradient to the text
            WebkitTextFillColor: "transparent", // Make the gradient visible
          }}
        >
          Our Vision
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#ffffff", // White text
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
            textAlign: "justify",
          }}
        >
          At I Edu Tales we don't just tell stories, we empower children to
          create, explore and learn in new ways. We are passionate about
          promoting creativity, language development and cultural awareness.
        </Typography>
        {/* Contact Information */}
        <Typography
          variant="body1"
          sx={{
            color: "#ffffff",
            fontSize: "1.2rem",
            lineHeight: "1.8",
            mb: 4,
            textAlign: "center", // Center-align the contact information
            fontWeight: "bold", // Emphasize the contact information
          }}
        >
          For more information, contact us :{" "}
          <Typography
            component="span"
            sx={{
              color: "#4cb4f6", // Highlight email in blue
              fontWeight: "bold",
              fontsize: "1.2rem",
            }}
          >
            <a
              href="mailto:iedutales@gmail.com"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              iedutales@gmail.com
            </a>
          </Typography>
        </Typography>
      </Container>
    </Box>
  );
};

export default OurStorySection;
