import React, { useState } from "react";
import {
  Box,
  Container,
  Grid2,
  Typography,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  const navigate = useNavigate();

  const handleClose = () => {
    setDialog({ ...dialog, open: false });
  };

  return (
    <Box sx={{ bgcolor: "#111827", color: "white", py: 8 }}>
      <Container>
        <Grid2
          container
          spacing={4}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* I Edu Tales and Quick Links Section */}
          <Grid2 container spacing={4} alignItems="flex-start" sx={{ mb: 4 }}>
            {/* I Edu Tales */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "#4cb4f6",
                  mb: 2,
                }}
              >
                I Edu Tales
              </Typography>
              <Typography sx={{ color: "#9ca3af", fontSize: "1rem" }}>
                Our platform uses AI to create interactive stories that inspire
                children to read, learn, and develop essential skills through
                engaging and personalised experiences.
              </Typography>
            </Grid2>

            {/* Quick Links */}

            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  mt: 3,
                }}
              >
                Quick Links
              </Typography>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Link
                      onClick={() => {
                        navigate("/home"); // Navigate to the home page
                        window.scrollTo({ top: 0 }); // Scroll to the top of the page
                      }}
                      color="#9ca3af"
                      underline="hover"
                      sx={{
                        cursor: "pointer", // Ensures the link looks clickable
                        "&:hover": {
                          color: "#ffffff",
                        },
                      }}
                    >
                      Home
                    </Link>
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Link
                      onClick={() => {
                        navigate("/our-story");
                        window.scrollTo({ top: 0 });
                      }} // Navigate to the Our Story page
                      color="#9ca3af"
                      underline="hover"
                      sx={{
                        cursor: "pointer", // Ensures the link looks clickable
                        "&:hover": {
                          color: "#ffffff",
                        },
                      }}
                    >
                      Our Story
                    </Link>
                  </Box>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>

        {/* Copyright Section */}
        <Box
          sx={{
            borderTop: "1px solid #444",
            mt: 6,
            pt: 3,
            textAlign: "center",
            color: "grey.500",
          }}
        >
          <Typography variant="body2">
            &copy; 2025 I Edu Tales. All rights reserved.
          </Typography>
        </Box>

        {/* Dialog for Success/Failure Message */}
        <Dialog
          open={dialog.open}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "12px",
              padding: "16px",
            },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "#2563eb",
            }}
          >
            I Edu Tales
          </DialogTitle>

          <DialogContent
            sx={{
              textAlign: "center",
              py: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                color: "#4b5563",
                fontWeight: "medium",
              }}
            >
              {dialog.message}
            </Typography>
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: "center",
              pb: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: dialog.isSuccess ? "#2563eb" : "#ef4444",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: dialog.isSuccess ? "#1d4ed8" : "#dc2626",
                },
                textTransform: "uppercase",
                fontWeight: "bold",
                borderRadius: "8px",
                px: 4,
                py: 1,
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default Footer;
