// theme.ts
import { createTheme } from "@mui/material/styles";
import "@fontsource/poppins"; // Import Poppins font

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif", // Set Poppins as the default font
  },
});

export default theme;
