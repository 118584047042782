import React from "react";
import { Toolbar } from "@mui/material";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactSection from "../components/ContactSection";
import AboutSection from "../components/AboutSection";
import WhyUsSection from "../components/WhyUsSection";
import HomeSection from "../components/HomeSection";
import ProductSection from "../components/ProductSection";

const HomePage: React.FC = () => {
  return (
    <>
      {/** ========== HEADER / NAVBAR ========== **/}
      <Header />
      <Toolbar />
      <HomeSection />
      <ProductSection />
      <WhyUsSection />
      <AboutSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default HomePage;
