import React from "react";
import { Box, Typography, Container, Grid2 } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";

const ContactSection: React.FC = () => {
  return (
    <Box id="contact" sx={{ py: 10, bgcolor: "#f9fafb" }}>
      <Container maxWidth="lg">
        <Grid2 container spacing={4} alignItems="center">
          {/* Left Side - Contact Info */}
          <Grid2 size={{xs: 12, md : 6}}>
            <Box sx={{ maxWidth: "500px", mx: "auto", textAlign: "left" }}>
              {/* Section Title */}
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", mb: 4, textAlign: "left" }}
              >
                Contact Us
              </Typography>

              {/* Contact Info Section */}
              <Box>
                <Typography color="#4b5563" sx={{ mb: 3 }}>
                  Have questions? We'd love to hear from you.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    gap: 1,
                  }}
                >
                  {/* Mail Icon */}
                  <MailIcon sx={{ color: "#2563eb", fontSize: 24 }} />

                  {/* Email Text */}
                  <Typography>
                    <a
                      href="mailto:iedutales@gmail.com"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.textDecoration = "none")
                      }
                    >
                      iedutales@gmail.com
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>

          {/* Right Side - Image */}
          <Grid2 size={{xs: 12, md : 6}}>
            <Box
              component="img"
              src="/profile/monster.png"
              alt="Contact Us Illustration"
              sx={{
                width: "100%",
                maxWidth: "550px",
                mx: "auto",
                display: "block",
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default ContactSection;
