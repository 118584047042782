import React from "react";
import { Box, Container, Typography, Grid2, Card } from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import PublicIcon from "@mui/icons-material/Public";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

const ProductSection: React.FC = () => {
  return (
    <Box
      id="product"
      sx={{ py: 10, bgcolor: "#f9fafb" }} // gray 50
    >
      <Container maxWidth="lg">
        {/* Section Title */}
        <Typography
          variant="h3"
          align="center"
          sx={{ fontWeight: "bold", mb: 8 }}
        >
          Our Product
        </Typography>

        {/* Grid2 Layout for Features */}
        <Grid2 container spacing={4} alignItems="stretch">
          {/* Feature 1 */}
          <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
            <Card
              sx={{
                p: 3, // Reduced padding for a compact look
                borderRadius: 4,
                bgcolor: "#FFFBEB",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                height: "auto", // Allow cards to adjust based on content
                minHeight: "230px", // Set a minimum height for uniformity
                maxHeight: "260px", // Limit the maximum height
                overflow: "hidden", // Prevent content overflow
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <MenuBookIcon sx={{ fontSize: 28, color: "#F59E0B" }} />
              </Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 0.5, fontSize: "1.1rem" }}
              >
                Interactive Storytelling
              </Typography>
              <Typography
                color="#4b5563"
                sx={{ flexGrow: 1, fontSize: "1rem" }}
              >
                Engaging narratives that adapt to each child's choices,
                interests, and learning pace.
              </Typography>
            </Card>
          </Grid2>

          {/* Feature 2 */}
          <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
            <Card
              sx={{
                p: 3, // Reduced padding for a compact look
                borderRadius: 4,
                bgcolor: "#ECFDF5",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                height: "auto", // Allow cards to adjust based on content
                minHeight: "230px", // Set a minimum height for uniformity
                maxHeight: "260px", // Limit the maximum height
                overflow: "hidden", // Prevent content overflow
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <GTranslateIcon sx={{ fontSize: 28, color: "#10B981" }} />
              </Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 0.5, fontSize: "1.1rem" }}
              >
                Language Learning
              </Typography>
              <Typography
                color="#4b5563"
                sx={{ flexGrow: 1, fontSize: "1rem" }}
              >
                Multi-language support that helps children naturally develop language skills through immersive stories.
              </Typography>
            </Card>
          </Grid2>

          {/* Feature 3 */}
          <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
            <Card
              sx={{
                p: 3, // Reduced padding for a compact look
                borderRadius: 4,
                bgcolor: "#EFF6FF",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                height: "auto", // Allow cards to adjust based on content
                minHeight: "230px", // Set a minimum height for uniformity
                maxHeight: "260px",// Limit the maximum height
                overflow: "hidden", // Prevent content overflow
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <PublicIcon sx={{ fontSize: 28, color: "#3B82F6" }} />
              </Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 0.5, fontSize: "1.1rem" }}
              >
                Cultural Exploration
              </Typography>
              <Typography
                color="#4b5563"
                sx={{ flexGrow: 1, fontSize: "1rem" }}
              >
                Stories from diverse cultures that foster understanding, inclusivity, and global awareness.
              </Typography>
            </Card>
          </Grid2>

          {/* Feature 4 */}
          <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
            <Card
              sx={{
                p: 3, // Reduced padding for a compact look
                borderRadius: 4,
                bgcolor: "#F5F3FF",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                height: "auto", // Allow cards to adjust based on content
                minHeight: "230px", // Set a minimum height for uniformity
                maxHeight: "260px", // Limit the maximum height
                overflow: "hidden", // Prevent content overflow
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <AutoStoriesIcon sx={{ fontSize: 28, color: "#8B5CF6" }} />
              </Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 0.5, fontSize: "1.1rem" }}
              >
                Printable Books
              </Typography>
              <Typography
                color="#4b5563"
                sx={{ flexGrow: 1, fontSize: "1rem" }}
              >
                Transform digital stories into high-quality, personalised printed books to create lasting memories.
              </Typography>
            </Card>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default ProductSection;
