import React, { useState, useEffect } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import "@fontsource/poppins";

interface FillInTheBlankProps {
  questions: {
    sentence: string;
    correct: string;
    image: string; // Add the image property here
  }[];
  currentQuestion: number;
  handleAnswer: (selected: string) => void;
  message: string;
}

const FillInTheBlank: React.FC<FillInTheBlankProps> = ({
  questions,
  currentQuestion,
  handleAnswer,
  message,
}) => {
  const [userInput, setUserInput] = useState(""); // State to track user's input
  const [showHelp, setShowHelp] = useState(false); // State to toggle image visibility
  const { sentence, image } = questions[currentQuestion]; // Get sentence and image for current question

  // Hide help when the current question changes
  useEffect(() => {
    setShowHelp(false);
  }, [currentQuestion]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    handleAnswer(userInput.trim()); // Pass trimmed input to the parent handler
    setUserInput(""); // Clear input after submission
  };

  const handleHelpClick = () => {
    setShowHelp(!showHelp); // Toggle the visibility of the image
  };

  return (
    <Container
      maxWidth="lg"
      style={{
        height: "60vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f9fafb",
      }}
    >
      <Typography
        variant="h5"
        align="center"
        style={{
          fontWeight: 700,
          color:
            message === "Correct!"
              ? "#059669" // Green 600 for "Correct!"
              : message === "Try Again!"
              ? "#dc2626" // Red 600 for "Try Again!"
              : "#4338ca", // Default blue for other cases
          marginBottom: "1rem",
        }}
      >
        {message || "Fill in the blank:"}
      </Typography>

      <Typography
        variant="h6"
        align="center"
        style={{
          fontWeight: 600,
          color: "#333",
          marginBottom: "1rem",
        }}
      >
        {sentence.replace("___", "_____")} {/* Display sentence with a blank */}
      </Typography>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Type your answer"
          value={userInput}
          onChange={handleInputChange}
          style={{
            width: "300px",
          }}
        />

        {/* Button Row */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem", // Spacing between buttons
            marginBottom: "1rem",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#4cb4f6", // blue 600
              color: "#ffffff",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#39a3e0", // blue 700
              },
            }}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            onClick={handleHelpClick}
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              borderColor: "#059669", // Green 600 for the border
              color: showHelp ? "#ffffff" : "#059669", // White text when showHelp is true, Green 600 otherwise
              backgroundColor: showHelp ? "#059669" : "transparent", // Green 600 background when showHelp is true, transparent otherwise
              "&:hover": {
                backgroundColor: "#047857", // Slightly darker green (Green 700) on hover
                color: "#ffffff", // White text on hover
              },
            }}
          >
            Help
          </Button>
        </Box>
      </Box>

      {/* Show Image if Help is toggled */}
      {showHelp && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <img
            src={image}
            alt="Visual clue"
            style={{
              maxWidth: "250px",
              maxHeight: "250px",
            }}
          />
        </Box>
      )}
    </Container>
  );
};

export default FillInTheBlank;
