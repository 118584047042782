import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import "@fontsource/poppins";

const PictureMultipleChoice: React.FC<{
  questions: Array<{
    image: string;
    options: string[];
    correct: string;
  }>;
  currentQuestion: number;
  handleAnswer: (selected: string) => void;
  message: string;
}> = ({ questions, currentQuestion, handleAnswer, message }) => {
  return (
    <Container
      maxWidth="lg"
      style={{
        height: "60vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f9fafb",
      }}
    >
      <img
        src={questions[currentQuestion].image}
        alt="Quiz Question"
        style={{
          maxWidth: "100%",
          maxHeight: "300px",
          marginBottom: "1rem",
        }}
      />
      <Typography
        variant="h5"
        align="center"
        style={{
          fontWeight: 700,
          color:
            message === "Correct!"
              ? "#059669" // Green 600 for "Correct!"
              : message === "Try Again!"
              ? "#dc2626" // Red 600 for "Try Again!"
              : "#4338ca", // Default blue for other cases
          marginBottom: "1rem",
        }}
      >
        {message || "What is this?"}
      </Typography>
      <Box style={{ display: "flex", gap: "1rem" }}>
        {questions[currentQuestion].options.map((option) => (
          <Button
            key={option}
            variant="contained"
            onClick={() => handleAnswer(option)}
            sx={{
              backgroundColor: "#4cb4f6", // blue 600
              color: "#ffffff",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#39a3e0", // blue 700
              },
            }}
          >
            {option}
          </Button>
        ))}
      </Box>
    </Container>
  );
};

export default PictureMultipleChoice;
