import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid2,
  Card,
  CardContent,
  Avatar,
  IconButton,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const AboutSection: React.FC = () => {
  return (
    <Box id="about" sx={{ py: 10, bgcolor: "white" }}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <Box
          sx={{ maxWidth: "900px", mx: "auto", textAlign: "center", mb: 10 }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", mb: 5, fontSize: "2.4rem" }}
          >
            About Us
          </Typography>
          <Typography
            variant="body1"
            color="#4b5563"
            sx={{
              fontSize: "1.15rem",
              lineHeight: "2",
              fontWeight: "400",
              letterSpacing: "0.5px",
            }}
          >
            We are a passionate team of innovators dedicated to transforming
            education through the power of storytelling. By blending technology,
            creativity, and education, we create immersive and personalised
            experiences that inspire children to imagine, learn, and thrive in a
            rapidly evolving world. Together, we are shaping the future of
            learning, one story at a time!
          </Typography>
        </Box>
        {/* Team Members */}
        <Grid2 container spacing={4} justifyContent="center">
          {/* Team Member 1 */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Card
              sx={{
                textAlign: "center",
                p: 4,
                borderRadius: "12px",
                boxShadow: 2,
              }}
            >
              <Avatar
                src="/profile/hanwool.jpg"
                alt="Sarah Johnson"
                sx={{
                  width: 128,
                  height: 128,
                  mx: "auto",
                  mb: 2,
                }}
              />
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Hanwool Park
                </Typography>
                <Typography color="#4b5563" sx={{ mb: 2 }}>
                  Founder
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  <IconButton
                    href="https://www.linkedin.com/in/hanwool-park/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "grey.500",
                      "&:hover": { color: "primary.main" },
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid2>

          {/* Team Member 2 */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Card
              sx={{
                textAlign: "center",
                p: 4,
                borderRadius: "12px",
                boxShadow: 2,
              }}
            >
              <Avatar
                src="/profile/valentina.jpg"
                alt="Michael Chen"
                sx={{
                  width: 128,
                  height: 128,
                  mx: "auto",
                  mb: 2,
                }}
              />
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Valentina Marzano
                </Typography>
                <Typography color="#4b5563" sx={{ mb: 2 }}>
                  Founder
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  <IconButton
                    href="https://www.linkedin.com/in/valentinamarzano/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "grey.500",
                      "&:hover": { color: "primary.main" },
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default AboutSection;
