import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "../pages/HomePage";
import DemoPage from "../pages/DemoPage"; // New Demo Page
import OurStoryPage from "../pages/OurStoryPage";


const AppRoutes: React.FC = () => (
  <Router>
    <Routes>
      {/* Redirect "/" to "/home" */}
      <Route path="/" element={<Navigate to="/home" replace />} />
      {/* Pages */}
      <Route path="/home" element={<HomePage />} />
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/our-story" element={<OurStoryPage />} />
    </Routes>
  </Router>
);

export default AppRoutes;
