import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import "@fontsource/poppins";

interface WordMatchingProps {
  questions: {
    definition: string;
    options: string[];
    correct: string;
    image: string;
  }[];
  currentQuestion: number;
  handleAnswer: (selected: string) => void;
  message: string;
}

const WordMatching: React.FC<WordMatchingProps> = ({
  questions,
  currentQuestion,
  handleAnswer,
  message,
}) => {
  const { definition, options, image } = questions[currentQuestion];
  const [showHelp, setShowHelp] = useState(false); // State to control visibility of the help image

  // Hide help when the current question changes
  useEffect(() => {
    setShowHelp(false);
  }, [currentQuestion]);

  const handleHelpClick = () => {
    setShowHelp(!showHelp); // Toggle the visibility of the help image
  };

  return (
    <Container
      maxWidth="lg"
      style={{
        height: "60vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f9fafb",
      }}
    >
      <Typography
        variant="h5"
        align="center"
        style={{
          fontWeight: 700,
          color:
            message === "Correct!"
              ? "#059669" // Green 600 for "Correct!"
              : message === "Try Again!"
              ? "#dc2626" // Red 600 for "Try Again!"
              : "#4338ca", // Default blue for other cases
          marginBottom: "1rem",
        }}
      >
        {message || "Match the definition to the correct word:"}
      </Typography>

      <Typography
        variant="h6"
        align="center"
        style={{
          fontWeight: 600,
          color: "#333",
          marginBottom: "1rem",
        }}
      >
        {definition} {/* Display the definition */}
      </Typography>

      <Box
        style={{
          display: "flex",
          flexDirection: "row", // Align the buttons horizontally
          justifyContent: "center", // Center the buttons
          gap: "1rem", // Add space between buttons
          marginBottom: "1rem",
        }}
      >
        {options.map((option) => (
          <Button
            key={option}
            variant="contained"
            onClick={() => handleAnswer(option)}
            sx={{
              backgroundColor: "#4cb4f6", // blue 600
              color: "#ffffff",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#39a3e0", // blue 700
              },
            }}
          >
            {option} {/* Display the option */}
          </Button>
        ))}
      </Box>

      {/* Help Button */}
      <Button
        variant="outlined"
        onClick={handleHelpClick}
        sx={{
          fontSize: "1rem",
          fontWeight: 600,
          borderColor: "#059669", // Green 600 for the border
          color: showHelp ? "#ffffff" : "#059669", // White text when showHelp is true, Green 600 otherwise
          backgroundColor: showHelp ? "#059669" : "transparent", // Green 600 background when showHelp is true, transparent otherwise
          "&:hover": {
            backgroundColor: "#047857", // Slightly darker green (Green 700) on hover
            color: "#ffffff", // White text on hover
          },
        }}
      >
        Help
      </Button>

      {/* Display the Image if Help is toggled */}
      {showHelp && (
        <Box
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={image}
            alt="Visual representation of the word" // Provide a more meaningful description
            style={{
              maxWidth: "300px",
              maxHeight: "300px",
            }}
          />
        </Box>
      )}
    </Container>
  );
};

export default WordMatching;
