export const pagesContent = [
  {
    title: "Page 1",
    content: `
      In the bustling futuristic city of Technotropolis, where hover-cars whizzed through the air and buildings sparkled like diamonds under the sun, 
      lived an extraordinary creature called Dino Saurus.
    `,
  },
  {
    title: "Page 2",
    content: `
      Dino was different from all the others. 
      With a heart of gold and the unique ability to become invisible, he roamed the streets helping the needy.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "00:00:00", endTime: "00:21:25" },
      woman: { startTime: "00:00:00", endTime: "00:20:14" },
    },
  },
  { title: "Page 3", content: "/text/1.png", type: "image" },
  {
    title: "Page 4",
    content: `
      Whenever someone was sad or in difficulty, Dino would silently appear, lending a hand or a word of comfort.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "00:21:25", endTime: "00:28:49" },
      woman: { startTime: "00:20:14", endTime: "00:27:27" },
    },
  },

  {
    title: "Page 5",
    content: `
     One sunny morning, as Dino explored the bustling Market Square, full of glittering holograms and floating stalls, 
     they came across their best friend, Polpetta the guinea pig.
    `,
    type: "text",
  },
  {
    title: "Page 6",
    content: `
      Polpetta, with her fluffy grey fur and bright pink scarf, was always full of curiosity.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "00:28:49", endTime: "00:46:58" },
      woman: { startTime: "00:27:27", endTime: "00:44:14" },
    },
  },

  {
    title: "Page 7",
    content: `
    ‘Dino! Look what I found!’ shouted Polpetta, jumping with excitement.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "00:46:58", endTime: "00:52:27" },
      woman: { startTime: "00:44:14", endTime: "00:49:05" },
    },
  },

  { title: "Page 8", content: "/text/2.png", type: "image" },
  {
    title: "Page 9",
    content: `
      She picked up an old book with a worn, colourful cover that glowed with energy.
    `,
    type: "text",
  },

  {
    title: "Page 10",
    content: `
     ‘What's inside?’ Dino asked, his eyes shining.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "00:52:27", endTime: "01:00:25" },
      woman: { startTime: "00:49:05", endTime: "00:56:52" },
    },
  },

  {
    title: "Page 11",
    content: `
      Polpetta carefully opened the book, revealing yellowed pages full of drawings and words.
    `,
    type: "text",
  },

  {
    title: "Page 12",
    content: `
    ‘It's a treasure map!’ she exclaimed, her moustache twitching in glee.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "01:00:25", endTime: "01:11:14" },
      woman: { startTime: "00:56:52", endTime: "01:06:39" },
    },
  },

  {
    title: "Page 13",
    content: `
    They rushed to a nearby tree and, when Dino's invisible fingers grazed the bark, they discovered a hidden compartment.
    `,
    type: "text",
  },
  {
    title: "Page 14",
    content: `
    Inside was a beautifully illustrated map, adorned with rhymes and riddles.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "01:11:14", endTime: "01:24:52" },
      woman: { startTime: "01:06:39", endTime: "01:19:05" },
    },
  },

  {
    title: "Page 15",
    content: `
    ‘Look! It says we have to go on a treasure hunt! Each clue will teach us something special and if we find the treasure, we can share it with everyone in the city!’ 
    Polpetta squeaked, opening her eyes wide.
    `,
    type: "text",
  },

  {
    title: "Page 16",
    content: `
     With a giggle, Dino replied: ‘Let's do it! Kindness and adventure await us!’.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "01:24:52", endTime: "01:42:27" },
      woman: { startTime: "01:19:05", endTime: "01:36:55" },
    },
  },

  {
    title: "Page 17",
    content: `
     The first clue led them to the Peace Tower, where two giant doves spiralled towards the sky.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "01:42:27", endTime: "01:48:30" },
      woman: { startTime: "01:36:55", endTime: "01:42:32" },
    },
  },

  { title: "Page 18", content: "/text/3.png", type: "image" },

  {
    title: "Page 19",
    content: `
    The riddle engraved on the map read: ‘To locate friends who never fight, look for the place where doves take flight’.
    `,
    type: "text",
  },

  {
    title: "Page 20",
    content: `
      Once at the tower, Dino became invisible and soared towards the pigeons perched on the ledge.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "01:48:30", endTime: "02:01:52" },
      woman: { startTime: "01:42:32", endTime: "01:55:43" },
    },
  },

  {
    title: "Page 21",
    content: `
     There they found a small scroll tied with a golden ribbon. The scroll revealed the lesson of kindness: 
     ‘Kindness is like a gentle breeze. It lifts others with such ease’.

    `,
    type: "text",
  },

  {
    title: "Page 22",
    content: `
     With their hearts swollen with excitement, Dino and Polpetta moved on to their next destination: Giggle Valley, where laughter echoed through the air.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "02:01:52", endTime: "02:22:19" },
      woman: { startTime: "01:55:43", endTime: "02:14:48" },
    },
  },

  {
    title: "Page 23",
    content: `
    The riddle was: ‘Giggles, giggles and joyful sounds. Look where pure happiness abounds'.
    `,
    type: "text",
  },
  {
    title: "Page 24",
    content: `
     Peering into a brightly-coloured playground crowded with children, Dino saw a child sitting alone on a swing.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "02:22:19", endTime: "02:34:58" },
      woman: { startTime: "02:14:48", endTime: "02:26:53" },
    },
  },

  {
    title: "Page 25",
    content: `
    Remembering the lesson of the first clue, Dino decided to offer his help. With a shared smile, 
    the two became visible together and Dino's magical presence brightened the boy's day.

    `,
    type: "text",
  },

  {
    title: "Page 26",
    content: `
    ‘Would you like to join us for an adventure?’ Dino asked, as Polpetta nodded enthusiastically.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "02:34:58", endTime: "02:52:28" },
      woman: { startTime: "02:26:53", endTime: "02:44:34" },
    },
  },

  {
    title: "Page 27",
    content: `
    ‘Really? Yes!’ exclaimed the boy, his eyes shining with joy.
    `,
    type: "text",
  },

  {
    title: "Page 28",
    content: `
    And with the help of two new friends, he soon joined the others in a joyful game.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "02:52:28", endTime: "03:02:19" },
      woman: { startTime: "02:44:34", endTime: "02:53:37" },
    },
  },

  {
    title: "Page 29",
    content: `
    As the sound of laughter filled the air, Dino and Polpetta found the second parchment hidden in the sandbox, revealing another lesson: 
    ‘Sharing joy is such a bright gift. It makes the world feel warm and just’.
    `,
    type: "text",
  },

  {
    title: "Page 30",
    content: `
   With hearts full of warmth for their new friendship, the duo headed for the third clue, which led them to the shimmering Crystal Lake.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "03:02:19", endTime: "03:23:46" },
      woman: { startTime: "02:53:37", endTime: "03:14:04" },
    },
  },

  { title: "Page 31", content: "/text/4.png", type: "image" },
  {
    title: "Page 32",
    content: `
    The riddle read: ‘Where reflections shine and whispers dance, the next clue is hidden. Take a chance'.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "03:23:46", endTime: "03:30:46" },
      woman: { startTime: "03:14:04", endTime: "03:21:09" },
    },
  },
  {
    title: "Page 33",
    content: `
    At the edge of the lake, Dino felt a ripple in the air and something unusual in the water. 
    They slipped beneath the surface, unseen by anyone.
    `,
    type: "text",
  },

  {
    title: "Page 34",
    content: `
    They found a glowing sphere pulsating with light. 
    It contained the next scroll, which conveyed the wisdom of courage: 
    ‘Courageous hearts will conquer fear so deep. With each step, a new faith you will keep’.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "03:30:46", endTime: "03:54:35" },
      woman: { startTime: "03:21:09", endTime: "03:42:09" },
    },
  },

  {
    title: "Page 35",
    content: `
    Dino and Polpetta exchange lessons learnt, their laughter mingling with the gentle sound of the waves as they become engrossed in their adventure.
    `,
    type: "text",
  },

  {
    title: "Page 36",
    content: `
   Eventually, following the last clue, they returned to the central square of Technotropolis, the place they called home.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "03:54:35", endTime: "04:10:56" },
      woman: { startTime: "03:42:09", endTime: "03:58:39" },
    },
  },

  {
    title: "Page 37",
    content: `
   The riddle they discovered read: ‘To find the treasure that shines best, look inside yourself, for love is the search’.
    `,
    type: "text",
  },

  {
    title: "Page 38",
    content: `
  With their hearts beating fast, Dino and Polpetta looked at the square full of friends and reunited comrades. 
  Dino, once again invisible, diffused their glowing presence.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "04:10:56", endTime: "04:31:23" },
      woman: { startTime: "03:58:39", endTime: "04:17:30" },
    },
  },

  {
    title: "Page 39",
    content: `
    When they looked deeper, they realised that the treasure they were looking for was not gold or jewellery, 
    but the kindness, joy and courage they had shared along the journey.
    `,
    type: "text",
  },

  {
    title: "Page 40",
    content: `
    ‘Let us celebrate what we have found!’ 
    Said Dino, as they finally became visible to everyone behind the shimmering curtain of kindness.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "04:31:23", endTime: "04:50:39" },
      woman: { startTime: "04:17:30", endTime: "04:35:37" },
    },
  },

  {
    title: "Page 41",
    content: `
    The citizens of Technotropolis joined in a vibrant celebration, sharing laughter and stories, 
    realising that love and friendship are truly the greatest treasures of all.
    `,
    type: "text",
    audioTimings: {
      man: { startTime: "04:50:39", endTime: "05:01:39" },
      woman: { startTime: "04:35:37", endTime: "04:46:16" },
    },
  },

  { title: "Page 42", content: "/text/5.png", type: "image" },
  {
    title: "Page 43",
    content: `
    Since that day, Dino Saurus and Polpetta have become the guardians of kindness in Technotropolis, reminding everyone that the real treasure lies in our hearts.
    `,
    type: "text",
  },

  {
    title: "Page 44",
    content: "The End!",
    type: "text",
    audioTimings: {
      man: { startTime: "05:01:39", endTime: "05:12:40" },
      woman: { startTime: "04:46:16", endTime: "04:56:48" },
    },
  },
];
