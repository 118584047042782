import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  Container,
  Button,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current page is `/demo` or `/our-story`
  const isSpecialPage =
    location.pathname === "/demo" || location.pathname === "/our-story";

  return (
    <AppBar
      position={isSpecialPage ? "static" : "fixed"}
      elevation={isSpecialPage ? 0 : undefined}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        backdropFilter: "blur(8px)",
        boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 50,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: "space-between", height: "80px" }}>
          {/* Logo Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          >
            {/* Logo */}
            <Box
              component="img"
              src="/profile/logo_without_text.png" // Replace with your logo's path
              alt="I Edu Tales Logo"
              sx={{
                height: "50px", // Adjust the height of the logo
                marginRight: "-10px", // Space between logo and text
              }}
            />
            {/* Text */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#4cb4f6", // blue 600
              }}
            >
              I Edu Tales
            </Typography>
          </Box>

          {/* Navigation Links - Hidden on small screens */}
          {!isSpecialPage && (
            <Box
              component="nav"
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 4,
                alignItems: "center",
              }}
            >
              <Link
                href="#home"
                underline="none"
                color="#4b5563" // gray 600
                sx={{ "&:hover": { color: "#2563eb" } }} // blue 600
              >
                Home
              </Link>
              <Link
                href="#product"
                underline="none"
                color="#4b5563"
                sx={{ "&:hover": { color: "#2563eb" } }}
              >
                Our Product
              </Link>
              <Link
                href="#why-us"
                underline="none"
                color="#4b5563"
                sx={{ "&:hover": { color: "#2563eb" } }}
              >
                Why Choose Us
              </Link>
              <Link
                href="#about"
                underline="none"
                color="#4b5563"
                sx={{ "&:hover": { color: "#2563eb" } }}
              >
                About
              </Link>
              <Link
                href="#contact"
                underline="none"
                color="#4b5563"
                sx={{ "&:hover": { color: "#2563eb" } }}
              >
                Contact
              </Link>
            </Box>
          )}

          {/* Right Section with Join Button and Mobile Menu */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/demo");
                window.scrollTo({ top: 0 });
              }}
              sx={{
                backgroundColor: "#4cb4f6", // blue 600
                color: "#ffffff",
                borderRadius: "999px",
                px: 3,
                py: 1,
                textTransform: "none",
                display: { xs: "none", md: "inline-flex" },
                "&:hover": {
                  backgroundColor: "#39a3e0", // blue 700
                },
              }}
            >
              Demo
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
