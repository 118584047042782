import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Grid2,
  Button,
} from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import { pagesContent } from "../data/pagesContent";
import "@fontsource/poppins";

const DemoText: React.FC<{
  setShowLearningSection: (value: boolean) => void;
}> = ({ setShowLearningSection }) => {
  const maxPages = pagesContent.length / 2 + 1; // Total page pairs
  const [currentPage, setCurrentPage] = useState(0);
  const [voiceGender, setVoiceGender] = useState("man"); // Default voice option
  const [isPlaying, setIsPlaying] = useState(false); // Audio playing state
  const [isPaused, setIsPaused] = useState(false);
  const [audioTimingsData, setAudioTimingsData] = useState<
    {
      pageNumber: number;
      man: { startTime: string; endTime: string };
      woman: { startTime: string; endTime: string };
    }[]
  >([]);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const timeTrackingRef = useRef<NodeJS.Timeout | null>(null);
  const currentPageRef = useRef(currentPage); // useRef for currentPage

  const handleNext = () => {
    setShowLearningSection(true); // Update state to show DemoLearn
  };

  // Sync currentPage state with currentPageRef
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  // Extract audioTimings and save them in state
  useEffect(() => {
    const extractedTimings = pagesContent
      .map((page, index) => {
        if (page.audioTimings) {
          return {
            pageNumber: Math.floor(index / 2) + 1, // Calculate the corresponding page pair
            man: page.audioTimings.man,
            woman: page.audioTimings.woman,
          };
        }
        return null;
      })
      .filter((data) => data !== null); // Filter out pages without audioTimings

    setAudioTimingsData(
      extractedTimings as {
        pageNumber: number;
        man: { startTime: string; endTime: string };
        woman: { startTime: string; endTime: string };
      }[]
    );
  }, []);

  // Navigation Handlers
  const nextPage = () => {
    if (currentPage + 1 < maxPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage - 1 >= 0) setCurrentPage(currentPage - 1);
  };

  const handlePageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pageNum = parseInt(e.target.value, 10);
    if (pageNum >= 1 && pageNum <= maxPages) {
      setCurrentPage(pageNum - 1);
    }
  };

  // Function to convert "mm:ss:ms" to seconds
  const timeToSeconds = (time: string) => {
    const [minutes, seconds, milliseconds] = time.split(":").map(Number);
    return minutes * 60 + seconds + milliseconds / 100;
  };

  const startAudioTracking = () => {
    if (timeTrackingRef.current) {
      clearInterval(timeTrackingRef.current);
    }

    timeTrackingRef.current = setInterval(() => {
      if (audioRef.current) {
        const currentTime = audioRef.current.currentTime;

        const currentTiming = audioTimingsData.find(
          (data) => data.pageNumber === currentPageRef.current + 1 // Use currentPageRef
        );

        if (currentTiming) {
          // Ensure voiceGender is valid or use a fallback
          const timingForGender =
            voiceGender === "man" || voiceGender === "woman"
              ? currentTiming[voiceGender]
              : currentTiming["man"]; // Default to "man" if voiceGender is invalid

          const endSeconds = timeToSeconds(timingForGender.endTime);

          if (currentTime >= endSeconds) {
            // Move to the next page
            if (currentPageRef.current + 1 < maxPages) {
              setCurrentPage((prev) => prev + 1);
            } else {
              stopAudioTracking(); // Stop tracking when reaching the last page
            }
          }
        }
      }
    }, 500); // Check every 500ms
  };

  // Stop audio tracking
  const stopAudioTracking = () => {
    if (timeTrackingRef.current) {
      clearInterval(timeTrackingRef.current);
      timeTrackingRef.current = null;
    }
  };

  const toggleVoice = () => {
    if (isPlaying && !isPaused) {
      // Pause audio
      audioRef.current?.pause();
      stopAudioTracking();
      setIsPaused(true); // Mark as paused
      setIsPlaying(false);
    } else if (isPaused) {
      audioRef.current?.play();
      startAudioTracking();
      setIsPaused(false); // Unmark as paused
      setIsPlaying(true);
    } else {
      // Start playing from the current page or the beginning
      const audioFile = `/${
        voiceGender === "man" ? "Dino_man.mp3" : "Dino_woman.mp3"
      }`;
      const audio = new Audio(audioFile);
      audioRef.current = audio;

      audio.play();
      setIsPlaying(true);
      startAudioTracking();

      setCurrentPage(0);

      audio.addEventListener("ended", () => {
        stopAudioTracking();
        setIsPlaying(false);
        audioRef.current = null;
      });
    }
  };

  const handleGenderChange = (
    event: React.MouseEvent<HTMLElement>,
    newGender: string
  ) => {
    if (newGender) setVoiceGender(newGender);
  };

  const renderPageContent = (page: any) => {
    if (page.type === "image") {
      return (
        <CardContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: 0,
          }}
        >
          <img
            src={page.content}
            alt="Page Content"
            style={{
              width: "auto", // Maintain aspect ratio
              height: "auto", // Maintain aspect ratio
              maxWidth: "95%", // Prevent overflow in width
              maxHeight: "95%", // Prevent overflow in height
              objectFit: "contain", // Ensure image scales properly
            }}
          />
        </CardContent>
      );
    }
    return (
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          // textAlign: "center",
          padding: "1rem",
        }}
      >
        <Typography
          variant="body1"
          style={{
            fontSize: "1.5rem",
            lineHeight: "2.5rem",
            color: "#6b7280",
            maxWidth: "80%",
          }}
        >
          {page.content}
        </Typography>
      </CardContent>
    );
  };

  return (
    <Container
      maxWidth="lg"
      style={{ height: "78vh", marginTop: "1rem", marginBottom: "2rem" }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          marginBottom: "1rem",
          flexWrap: "wrap",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <IconButton
            onClick={prevPage}
            disabled={isPlaying || isPaused || currentPage === 0}
          >
            <ForwardIcon
              style={{ transform: "rotate(180deg)" }}
              sx={{
                color: !(isPlaying || isPaused || currentPage === 0)
                  ? "#4cb4f6"
                  : "#aadef6",
                fontSize: "2.5rem",
              }}
            />
          </IconButton>

          <TextField
            variant="outlined"
            type="number"
            value={currentPage + 1}
            onChange={handlePageChange}
            size="small"
            disabled={isPlaying || isPaused}
            sx={{
              bgcolor: "white",
              "& input": {
                min: 1,
                max: maxPages,
                textAlign: "center",
              },
              width: "80px",
            }}
          />
          <Typography
            variant="body2"
            style={{ fontSize: "0.9rem", fontFamily: "'Poppins', sans-serif" }}
          >
            of {maxPages}
          </Typography>

          <IconButton
            onClick={nextPage}
            disabled={isPlaying || isPaused || currentPage + 1 >= maxPages}
          >
            <ForwardIcon
              sx={{
                color: !(isPlaying || isPaused || currentPage + 1 >= maxPages)
                  ? "#4cb4f6"
                  : "#aadef6",
                fontSize: "2.5rem",
              }}
            />
          </IconButton>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <ToggleButtonGroup
            value={voiceGender}
            exclusive
            onChange={handleGenderChange}
            aria-label="Voice Gender"
            disabled={isPlaying || isPaused}
          >
            <ToggleButton
              value="man"
              sx={{
                bgcolor:
                  voiceGender === "man"
                    ? "#4cb4f6 !important" // Updated to vibrant blue for selected
                    : "#ffffff !important", // White for unselected
                color:
                  voiceGender === "man"
                    ? "#ffffff !important" // White text for selected
                    : "#1f2937 !important", // Dark gray for unselected text
                border: "1px solid #d1d5db", // Light gray border
                "&:hover": {
                  bgcolor: "#c9ecfb !important", // Lighter shade of blue for hover
                },
              }}
            >
              Man
            </ToggleButton>
            <ToggleButton
              value="woman"
              sx={{
                bgcolor:
                  voiceGender === "woman"
                    ? "#4cb4f6 !important" // Updated to vibrant blue for selected
                    : "#ffffff !important", // White for unselected
                color:
                  voiceGender === "woman"
                    ? "#ffffff !important" // White text for selected
                    : "#1f2937 !important", // Dark gray for unselected text
                border: "1px solid #d1d5db", // Light gray border
                "&:hover": {
                  bgcolor: "#c9ecfb !important", // Lighter shade of blue for hover
                },
              }}
            >
              Woman
            </ToggleButton>
          </ToggleButtonGroup>
          {!isPlaying && !isPaused && (
            // Show Play button only initially or when stopped
            <IconButton
              onClick={toggleVoice}
              aria-label="Play Voice"
              sx={{
                color: "#4cb4f6",
              }}
            >
              <VolumeUpIcon fontSize="large" />
            </IconButton>
          )}

          {isPlaying && !isPaused && (
            <>
              {/* Pause button when audio is playing */}
              <IconButton
                onClick={() => {
                  // Pause the audio playback
                  audioRef.current?.pause();
                  stopAudioTracking();
                  setIsPaused(true);
                  setIsPlaying(false);
                }}
                aria-label="Pause Voice"
                sx={{
                  color: "#7c3aed",
                }}
              >
                <PauseIcon fontSize="large" />
              </IconButton>

              {/* Stop button when audio is playing */}
              <IconButton
                onClick={() => {
                  // Stop the audio playback
                  audioRef.current?.pause();
                  stopAudioTracking();
                  audioRef.current = null;
                  setIsPlaying(false);
                  setIsPaused(false);
                }}
                aria-label="Stop Voice"
                sx={{
                  color: "#dc2626",
                }}
              >
                <StopIcon fontSize="large" />
              </IconButton>
            </>
          )}

          {isPaused && (
            <>
              {/* Resume button when audio is paused */}
              <IconButton
                onClick={toggleVoice}
                aria-label="Resume Voice"
                sx={{
                  color: "#4cb4f6",
                }}
              >
                <VolumeUpIcon fontSize="large" />
              </IconButton>

              {/* Stop button when audio is paused */}
              <IconButton
                onClick={() => {
                  // Stop the audio playback
                  audioRef.current?.pause();
                  stopAudioTracking();
                  audioRef.current = null;
                  setIsPlaying(false);
                  setIsPaused(false);
                }}
                color="error"
                aria-label="Stop Voice"
              >
                <StopIcon fontSize="large" />
              </IconButton>
            </>
          )}
        </Box>
      </Box>

      <Box style={{ height: "80%", display: "flex" }}>
        <Grid2 container spacing={2} style={{ width: "100%", height: "100%" }}>
          {currentPage + 1 < maxPages ? (
            <>
              <Grid2 size={{ xs: 6 }} style={{ height: "100%" }}>
                <Card style={{ height: "100%" }}>
                  {renderPageContent(pagesContent[currentPage * 2])}
                </Card>
              </Grid2>

              <Grid2 size={{ xs: 6 }} style={{ height: "100%" }}>
                <Card style={{ height: "100%" }}>
                  {renderPageContent(pagesContent[currentPage * 2 + 1])}
                </Card>
              </Grid2>
            </>
          ) : (
            <>
              <Grid2
                container
                spacing={2}
                style={{ width: "100%", height: "100%" }}
              >
                {/* Left Page Content */}
                <Grid2 size={6}>
                  <Card
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "1.5rem",
                        color: "#6b7280",
                        padding: "1rem",
                      }}
                    >
                      Let's learn some new words!
                    </Typography>
                  </Card>
                </Grid2>

                {/* Right Page Content */}
                <Grid2 size={6}>
                  <Card
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      size="large"
                      sx={{
                        backgroundColor: "#4cb4f6", // blue 600
                        color: "#ffffff",
                        fontSize: "1.25rem",
                        "&:hover": {
                          backgroundColor: "#39a3e0", // blue 700
                        },
                      }}
                    >
                      Go Learn!
                    </Button>
                  </Card>
                </Grid2>
              </Grid2>
            </>
          )}
        </Grid2>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Centers the button horizontally
          margin: "2rem 0", // Adds margin at the top and bottom
        }}
      >
        <Button
          variant="contained"
          onClick={handleNext}
          size="large"
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            backgroundColor: "#7c3aed", // Purple color for background
            color: "#ffffff", // White text
            "&:hover": {
              backgroundColor: "#9d4edd", // Lighter purple on hover
            },
          }}
        >
          Learn Now
        </Button>
      </Box>
    </Container>
  );
};

export default DemoText;
