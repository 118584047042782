import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DemoText from "../components/DemoText";
import DemoLearn from "../components/DemoLearn";
import { Typography } from "@mui/material";
import "@fontsource/poppins";

const DemoPage: React.FC = () => {
  const [showLearningSection, setShowLearningSection] = useState(false);

  return (
    <div style={{ backgroundColor: "#f9fafb", minHeight: "100vh" }}>
      <Header />
      <Typography
        variant="h5"
        align="center"
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
          fontWeight: "bold",
          color: "#4cb4f6",
        }}
      >
        Dino’s Journey: Discovering the True Treasure
      </Typography>

      {!showLearningSection ? (
        <DemoText setShowLearningSection={setShowLearningSection} /> // Pass renamed function
      ) : (
        <DemoLearn setShowLearningSection={setShowLearningSection} />
      )}

      <Footer />
    </div>
  );
};

export default DemoPage;
