export type PictureMultipleChoiceQuestion = {
  image: string;
  options: string[];
  correct: string;
};

export type WordMatchingQuestion = {
  definition: string;
  options: string[]; // Use 'options' instead of 'match'
  correct: string;
  image: string;
};

export type FillInTheBlankQuestion = {
  sentence: string;
  correct: string;
  image: string;
};

export type QuestionsType = {
  "Picture & Multiple Choice": PictureMultipleChoiceQuestion[];
  "Word Matching": WordMatchingQuestion[];
  "Fill-in-the-Blank": FillInTheBlankQuestion[];
};

export const questions: QuestionsType = {
  "Picture & Multiple Choice": [
    {
      image: "/learn/treasure_image.png",
      options: ["Gold", "Treasure", "Rock"],
      correct: "Treasure",
    },
    {
      image: "/learn/map_image.png",
      options: ["Map", "Clock", "Book"],
      correct: "Map",
    },
    {
      image: "/learn/guineapig_image.png",
      options: ["Rabbit", "Guinea Pig", "Mouse"],
      correct: "Guinea Pig",
    },
    {
      image: "/learn/scarf_image.png",
      options: ["Hat", "Scarf", "Shoes"],
      correct: "Scarf",
    },
    {
      image: "/learn/gift_image.png",
      options: ["Gift", "Trash", "Tool"],
      correct: "Gift",
    },
    {
      image: "/learn/dinosaur_image.png",
      options: ["Dinosaur", "Elephant", "Snake"],
      correct: "Dinosaur",
    },
  ],
  "Word Matching": [
    {
      definition:
        "Something valuable or special, often hidden or hard to find.",
      options: ["Gold", "Treasure", "Jewel"],
      correct: "Treasure",
      image: "/learn/treasure_image.png",
    },
    {
      definition: "A drawing or diagram used to find directions or locations.",
      options: ["Map", "Book", "Sign"],
      correct: "Map",
      image: "/learn/map_image.png",
    },
    {
      definition: "A small, furry pet with short legs and no tail.",
      options: ["Rabbit", "Guinea Pig", "Mouse"],
      correct: "Guinea Pig",
      image: "/learn/guineapig_image.png",
    },
    {
      definition: "A piece of cloth worn around the neck to keep warm.",
      options: ["Scarf", "Hat", "Gloves"],
      correct: "Scarf",
      image: "/learn/scarf_image.png",
    },
    {
      definition:
        "Something given to someone as a present to show love or gratitude.",
      options: ["Gift", "Prize", "Tool"],
      correct: "Gift",
      image: "/learn/gift_image.png",
    },
    {
      definition: "A large reptile that lived millions of years ago.",
      options: ["Dinosaur", "Crocodile", "Whale"],
      correct: "Dinosaur",
      image: "/learn/dinosaur_image.png",
    },
  ],
  "Fill-in-the-Blank": [
    {
      sentence: "The hidden _ _ _ _ _ _ _ _ was found in a secret cave.",
      correct: "treasure",
      image: "/learn/treasure_image.png",
    },

    {
      sentence: "We followed the _ _ _ to find our way through the forest.",
      correct: "map",
      image: "/learn/map_image.png",
    },

    {
      sentence: "The fluffy _ _ _ _ _ _ _ _ _ _ squeaked happily while eating lettuce.",
      correct: "guinea pig",
      image: "/learn/guineapig_image.png",
    },

    {
      sentence: "She wrapped a warm _ _ _ _ _ around her neck on the cold day.",
      correct: "scarf",
      image: "/learn/scarf_image.png",
    },

    {
      sentence: "He gave her a special _ _ _ _ for her birthday.",
      correct: "gift",
      image: "/learn/gift_image.png",
    },

    {
      sentence: "The museum had a giant skeleton of a _ _ _ _ _ _ _ _ on display.",
      correct: "dinosaur",
      image: "/learn/dinosaur_image.png",
    },
  ],
};
