import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurStorySection from "../components/OurStorySection";

const OurStoryPage: React.FC = () => {
  return (
    <>
      {/** ========== HEADER / NAVBAR ========== **/}
      <Header />
      {/** ========== OUR STORY SECTION ========== **/}
      <OurStorySection />
      {/** ========== FOOTER ========== **/}
      <Footer />
    </>
  );
};
export default OurStoryPage;
