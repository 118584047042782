import { questions, QuestionsType } from "../data/questions"; // Import types for type safety
import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import PictureMultipleChoice from "./PictureMultipleChoice";
import WordMatching from "./WordMatching";
import FillInTheBlank from "./FillInTheBlank";
import "@fontsource/poppins";

const DemoLearn: React.FC<{
  setShowLearningSection: (value: boolean) => void;
}> = ({ setShowLearningSection }) => {
  const [currentGame, setCurrentGame] = useState<keyof QuestionsType>(
    "Picture & Multiple Choice"
  );
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [message, setMessage] = useState("");

  // Get questions for the current game type
  const currentQuestions = questions[currentGame];

  const handleAnswer = (selected: string) => {
    // Normalize both selected and correct answer to lowercase for case-insensitive comparison
    const isCorrect =
      selected.trim().toLowerCase() ===
      currentQuestions[currentQuestion].correct.trim().toLowerCase();

    setMessage(isCorrect ? "Correct!" : "Try Again!");

    if (isCorrect) {
      setTimeout(() => {
        if (currentQuestion < currentQuestions.length - 1) {
          setCurrentQuestion((prev) => prev + 1);
          setMessage(""); // Clear message for next question
        } else {
          setMessage("You finished the game!");
          
        }
      }, 1000);
    } else {
      // Reset "Try Again!" to default message after 2 seconds
      setTimeout(() => setMessage("What is this?"), 1000);
    }
  };

  const handleComeBack = () => {
    setShowLearningSection(false); // Navigate back to DemoText
  };

  const renderGameContent = () => {
    switch (currentGame) {
      case "Picture & Multiple Choice":
        return (
          <PictureMultipleChoice
            questions={
              currentQuestions as QuestionsType["Picture & Multiple Choice"]
            }
            currentQuestion={currentQuestion}
            handleAnswer={handleAnswer}
            message={message}
          />
        );
      case "Word Matching":
        return (
          <WordMatching
            questions={currentQuestions as QuestionsType["Word Matching"]}
            currentQuestion={currentQuestion}
            handleAnswer={handleAnswer}
            message={message}
          />
        );
      case "Fill-in-the-Blank":
        return (
          <FillInTheBlank
            questions={currentQuestions as QuestionsType["Fill-in-the-Blank"]}
            currentQuestion={currentQuestion}
            handleAnswer={handleAnswer}
            message={message}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Game Selection Buttons */}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          marginBottom: "1rem",
          marginTop: "1.5rem",
        }}
      >
        {Object.keys(questions).map((game) => (
          <Button
            key={game}
            variant="outlined" // Default to outlined for consistency
            sx={{
              borderColor: "#7c3aed", // Purple for the border
              backgroundColor: currentGame === game ? "#7c3aed" : "transparent", // Fill with purple when selected, otherwise transparent
              color: currentGame === game ? "#ffffff" : "#7c3aed", // White text when selected, purple text otherwise
              fontWeight: "bold",
              "&:hover": {
                backgroundColor:
                  currentGame === game ? "#9d4edd" : "rgba(124, 58, 237, 0.1)", // Lighter purple for selected or light purple hover effect for unselected
                borderColor: "#9d4edd", // Adjusted border color on hover
              },
            }}
            onClick={() => {
              setCurrentGame(game as keyof QuestionsType); // Cast to ensure type safety
              setCurrentQuestion(0); // Reset question index on game change
              setMessage(""); // Clear message
            }}
          >
            {game}
          </Button>
        ))}
      </Box>

      {/* Render Game Content */}
      {renderGameContent()}

      {/* "Come Back" Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "2rem 0", // Adds margin at the top and bottom
        }}
      >
        <Button
          variant="contained"
          onClick={handleComeBack}
          size="large"
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            backgroundColor: "#7c3aed", // Purple color for background
            color: "#ffffff", // White text
            "&:hover": {
              backgroundColor: "#9d4edd", // Lighter purple on hover
            },
          }}
        >
          Return to Book
        </Button>
      </Box>
    </div>
  );
};

export default DemoLearn;
